// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/news-article.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-place-tsx": () => import("./../../../src/templates/place.tsx" /* webpackChunkName: "component---src-templates-place-tsx" */),
  "component---src-templates-region-tsx": () => import("./../../../src/templates/region.tsx" /* webpackChunkName: "component---src-templates-region-tsx" */),
  "component---src-templates-tour-tsx": () => import("./../../../src/templates/tour.tsx" /* webpackChunkName: "component---src-templates-tour-tsx" */),
  "component---src-templates-wildlife-tsx": () => import("./../../../src/templates/wildlife.tsx" /* webpackChunkName: "component---src-templates-wildlife-tsx" */)
}

